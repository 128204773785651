<template>
  <Sidebar ref="sidebar" :title="$tuf('new_page')">
    <form @submit.prevent="save" class="pb-20">

      <Overlay v-if="loading" class="z-2 mt-16 flex justify-center items-center bg-white">
        <div class="bg-white shadow rounded w-8 h-8 flex justify-center items-center text-prasset-green-500">
          <LoadingIndicator />
        </div>
      </Overlay>

      <div class="relative z-1 grid grid-cols-6 gap-6">
        <div class="col-span-6">
          <label for="input_name" class="text-sm text-prasset-green-800 mb-2 block">
            Naam
          </label>
          <TextField id="input_name" name="name" v-model="form.name" :error="errors.name" />
        </div>
        <div class="col-span-6">
          <label for="input_name" class="text-sm text-prasset-green-800 mb-2 block">
            Type
          </label>
          <SelectField id="input_type" name="type" v-model="form.type"
            :error="errors.type"
            :optional="false"
            :options="{
              TYPE_PAGE: 'Pagina',
              TYPE_ARTICLE: 'Artikel, nieuwsbericht of blogpost',
            }"
          />
        </div>

        <div class="col-span-2">
          <label for="publish" class="text-sm text-prasset-green-800 mb-2 block">
            Pagina publiceren
          </label>
          <ToggleField
            name="publish"
            :value="false"
            placeholder=""
            @input="publish"
          />
        </div>

        <div class="col-span-4">
          <label for="hide_from_nav" class="text-sm text-prasset-green-800 mb-2 block">
            Pagina tonen in menu
          </label>
          <ToggleField
            name="hide_from_nav"
            :value="form.options.show_in_menu"
            placeholder=""
            v-model="form.options.show_in_menu"
          />
        </div>

        <div class="col-span-6">
          <h2 class="text-2xl text-green-900 mb-4 mt-8">Metadata</h2>
          <label for="input_title" class="text-sm text-prasset-green-800 mb-2 block">
            Pagina titel
          </label>
          <TextField id="input_title" name="title" v-model="form.metadata.title" :error="errors.title" />
        </div>
        <div class="col-span-6">
          <label for="input_description" class="text-sm text-prasset-green-800 mb-2 block">
            Pagina beschrijving
          </label>
          <TextareaField class="w-full" id="input_description" name="description" v-model="form.metadata.description" :error="errors.description" />
        </div>
        <!-- <div class="col-span-6">
          <label for="social_image" class="text-sm text-prasset-green-800 mb-2 block">
            Afbeelding voor socials
          </label>
          <p>TO:DO</p>
        </div> -->
      </div>

      <div class="w-full bg-white border-t border-gray-300 absolute bottom-0 inset-x-0 px-5 py-4 z-20">
        <div class="flex">
          <button class="button button--outlined mr-4" type="button" @click="$refs.sidebar.close()">
            {{ $tuf('cancel') }}
          </button>

          <button
            type="submit"
            class="button button--opague relative"
            :class="{ 'opacity-25': loading && submittedBy !== 'save-and-back' }"
            :disabled="loading"
          >
            <span :class="{ 'invisible': loading && submittedBy === 'save-and-back' }">
              {{ $tuf('save_and_back') }}
            </span>
            <span v-if="loading && submittedBy === 'save-and-back'" class="absolute inset-0 flex justify-center items-center">
              <LoadingIndicator />
            </span>
          </button>
        </div>
      </div>
    </form>
  </Sidebar>
</template>

<script>
import { toRefs, reactive, onMounted } from '@vue/composition-api';
import LoadingIndicator from '@/components/LoadingIndicator';
import Overlay from '@/components/Overlay';
import SelectField from '@/components/field/Select';
import Sidebar from '@/modules/core/views/components/Sidebar';
import TextField from '@/components/field/Text';
import TextareaField from '@/components/field/Textarea';
import useWebsite from '@/compositions/useWebsite';
import ToggleField from '@/components/field/Toggle';
import useChangedState from '@/compositions/useChangedState';

export default {
  name: 'PageCreate',

  components: {
    LoadingIndicator,
    Overlay,
    SelectField,
    ToggleField,
    Sidebar,
    TextField,
    TextareaField,
  },

  setup(props, { refs, root }) {
    const { project_id, website_id } = root.$route.params;
    const { createPage, reloadWebsite } = useWebsite();
    const { changed, watchChanges, commitChanges } = useChangedState();

    const state = reactive({
      form: {
        type: 'TYPE_PAGE',
        status: 'IS_DRAFT',
        metadata: {
          title: null,
        },
        options: {
          show_in_menu: true,
        },
      },
      errors: {},
      ready: false,
      changed,
      loading: false,
      submittedBy: null,
    });

    const publish = (status) => {
      state.form.status = status ? 'IS_PUBLISHED' : 'IS_DRAFT';
    };

    /**
     * Load form data.
     */
    async function load() {
      // state.loading = true;
      // await forSomeRequiredData();
      state.loading = false;
      state.ready = true;

      watchChanges(state, 'form');
    }

    /**
     * Save form data.
     */
    async function save(close = true, submittedBy = 'save-and-back') {
      state.submittedBy = submittedBy;
      state.loading = true;
      state.errors = {};

      try {
        const page = await createPage(project_id, website_id, state.form);
        await reloadWebsite(project_id, website_id);

        root.$notify({ type: 'success', title: 'Succes', text: 'Pagina is aangemaakt' });

        state.loading = false;

        commitChanges(state, 'form');

        if (close) {
          refs.sidebar.close();

          // navigate directly to the new page.
          root.$router.push({
            name: 'website-page',
            params: { website_id, page_id: page.id },
          });
        }
      } catch (error) {
        state.loading = false;
        state.errors = error.response.data.errors;
      }
    }

    onMounted(async () => {
      load();
    });

    return {
      ...toRefs(state),
      publish,
      save,
    };
  },
};
</script>
